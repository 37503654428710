import FlashLib from 'flashlib';
import { store, storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import { requestFieldOpen } from '../api/rest';
import { eCellState, eGameState } from '../redux/game/reducer';
import { greenStrokeShadow } from '../fontStyles';
import ControllerSounds, { eSoundType } from './controllerSounds';
import AnimationsLoader from './animations/animationsLoader';
import eAnimationType from './animations/eAnimationType';
import actions from '../redux/game/actions';

export default class ControllerFieldCell extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.interactive = true;
    this.buttonMode = true;
    this.possibleOpenAll = false;

    this.goToFrame(3);
    this.index = this.name.replace('cell_', '');

    this.on('pointertap', this.onCellClicked, this);
    this.label = this.getChildByName('text');
    this.label.style = { ...this.label.style, ...greenStrokeShadow };

    this.initCellAnimation();
    this.initSpinner();

    let state = '';
    this.cachedData = {};
    state = storeObserver.addListenerAsNewAndGetValue(selectors.getFieldCellInfo(this.index), this.onStateChanged);
    this.onStateChanged(state);
  }

  initSpinner() {
    this.spinner = AnimationsLoader.createAnimation(eAnimationType.EAT_SPINNER);
    this.spinner.x = this.width / 2;
    this.spinner.y = this.height / 2;
    this.spinner.state.setAnimation(0, 'spinner', true);
    this.spinner.visible = false;
    this.addChild(this.spinner);
  }

  initCellAnimation() {
    this.animation = AnimationsLoader.createAnimation(eAnimationType.EAT_CELL);
    this.animation.x = this.width / 2;
    this.animation.y = this.height / 2;
    this.removeChildAt(0);
    this.addChildAt(this.animation, 0);
    this.animation.state.addListener({
      complete: this.onAnimationComplete
    });
  }

  onAnimationComplete = (data) => {
    if (data.animation.name === eCellState.ECS_BOMB && this.possibleOpenAll) {
      store.dispatch(actions.openOtherCells());
    }
    this.possibleOpenAll = false;
    this.tryShowText();
  };

  showAnimation(type) {
    this.animation.state.setAnimation(0, type, false);
  }

  showSpinner = () => {
    if (this.spinner)
      this.spinner.visible = true;
  };

  hideSpinner = () => {
    if (this.spinner)
      this.spinner.visible = false;
  };

  onCellClicked = async () => {
    const state = store.getState();
    if (selectors.getGameState(state) !== eGameState.EGS_READY_TO_PLAY && this.cachedData.isClosed) {
      this.showSpinner();
      this.possibleOpenAll = true;
      requestFieldOpen(this.index)
        .finally(this.hideSpinner);
      ControllerSounds.playSound(eSoundType.EST_OPEN);
    }
  };

  onStateChanged = (data) => {
    if (data.isClosed) {
      this.animation.update(0);
      this.animation.skeleton.setToSetupPose();
      this.animation.state.tracks = [];
      this.possibleOpenAll = false;
    } else if (data.isClosed !== this.cachedData.isClosed || data.state !== this.cachedData.state) {
      this.showAnimation(data.state);
    }

    if (!data.isPath && !data.isClosed) {
      this.alpha = 0.5;
    } else {
      this.alpha = 1;
    }

    if (data.isClosed) {
      this.hideText();
    }

    const state = store.getState();
    this.buttonMode = selectors.getGameState(state) !== eGameState.EGS_READY_TO_PLAY && data.isClosed;

    this.cachedData = data;
  };

  tryShowText() {
    if (!this.cachedData.isClosed && this.cachedData.isPath && this.cachedData.state === eCellState.ECS_CLOVER) {
      this.label.text = `x${this.cachedData.coef}`;
    } else {
      this.hideText();
    }
  }

  hideText() {
    this.label.text = ' ';
  }
}
